import React from "react"

const ContactInfo = () => {
    return (
<div className="contact-flex_info">
<div className="info-wrapper">
  <div className="info-title">E-MAIL</div>
  <div className="info-description"><a href="mailto:info@mariakardami.com">info@mariakardami.com</a></div>
</div>
<div className="info-wrapper">
  <div className="info-title">PHONE</div>
  <div className="info-description"><a href="tel:+30 2103387760">+30 2103387760</a> | <a href="tel:+30 2117406008">+30 2117406008</a> </div>
</div> 
<div className="info-wrapper">
  <div className="info-title">ADDRESS</div>
  <div className="info-description"><a href="https://goo.gl/maps/TGND1rH5VhZKLMey5" target="_blank">Koubari 6, 10674, Kolonaki, Athens</a></div>
</div>
</div>
)
}

export default ContactInfo