import axios from "axios"
import SimpleReactValidator from 'simple-react-validator';
import React,{ useState } from "react"


const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const validator = new SimpleReactValidator({
    locale: "en",
    messages: { default: "Αυτό το πεδίο είναι υποχρεωτικό." },
    validators: {
      alphaWithGreek: {
        message: "Το όνομα έχει μη αποδεκτούς χαρακτήρες",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^[\u0370-\u03FF\u1F00-\u1FFFa-zA-Z\s]+$/u
            ) && params.indexOf(val) === -1
          )
        },
      },
    },
  })

  const handleSubmit = e => {
    e.preventDefault()
    const bodyFormData = new FormData()
    bodyFormData.append("your-name", name)
    bodyFormData.append("your-email", email)
    bodyFormData.append("your-message", message)
    if (validator.allValid()) {
      axios({
        method: "post",
        url:
          "https://cms.mariakardami.com/wp-json/contact-form-7/v1/contact-forms/6/feedback",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          alert(response.data.message)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      const topErrorMessage = Object.values(validator.errorMessages).find(
        message => message
      )
      alert("Something was wrong with your details!")
    }
  }
  return (
    <form className="form contact-form">
      <label htmlFor="your-name">
        NAME
        <input
          type="text"
          name="your-name"
          className="firstName"
          tabIndex="1"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
        {validator.message("name", name, "required|alphaWithGreek")}
      </label>
      <label htmlFor="your-email">
        EMAIL
        <input
          type="email"
          name="your-email"
          id="email"
          className="email"
          placeholder=""
          tabIndex="3"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        {validator.message("email", email, "required|email")}
      </label>
      <label htmlFor="your-message">
        MESSAGE
        <input
          placeholder=""
          className="message"
          name="your-message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          required
        />
        {validator.message("message", message, "required")}
      </label>
      <label htmlFor="submit_btn">SUBMIT</label>
      <button
        type="submit"
        onClick={handleSubmit}
        className="submit_btn"
        value="SUBMIT"
      ></button>
    </form>
  )
}

export default ContactForm
