import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ContactForm from "../components/Sections/ContactForm"
import ContactInfo from "../components/Sections/ContactInfo"
import parse from "html-react-parser"
import Seo from "../components/seo"

const ContactPage = ({data}) => {
  return (
    <Layout isOverflowHidden>
      <Seo title={data.wpPage.title} />
      <div className="container contact-container">
        <div className="contact-title">{parse(data.wpPage.content)}</div>
        <ContactForm/>
        <ContactInfo/>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    wpPage(title: {eq: "Contact"}) {
      id
      title
      content
    }
  }
`
export default ContactPage
